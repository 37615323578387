<template>
    <b-card title="Search Invoice">
        <b-form>
            <b-row>
                <b-col md="8">
                    <b-form-group label="Invoice / Barcode">
                        <b-form-input @input="onInputChange" placeholder="Please Enter Invoice / Order No" />
                    </b-form-group>
                </b-col>

            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                            <b-avatar :src="props.row.avatar" class="mx-1" />
                            <span class="text-nowrap">{{ props.row.fullName }}</span>
                        </span>

                        <!-- Column: Status -->
                        <span v-else-if="props.column.field === 'orderNo'">
                            <b-link v-b-toggle.sidebar-right-orderDetail
                                @click="getOrderDetail(props.row.orderId)">
                                {{ '#'+props.row.orderNo }}
                            </b-link>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="text-body align-middle mr-25" />
                                    </template>
                                    <b-dropdown-item>
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        <span>Edit</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <feather-icon icon="TrashIcon" class="mr-50" />
                                        <span>Delete</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
            <b-sidebar id="sidebar-right-orderDetail" width="80%" right shadow bg-variant="white" backdrop>
                <OrderDetail ref="orderDetail" />
            </b-sidebar>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar,VBToggle, BCard, BRow, BCol,BSidebar, BForm,BLink, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import OrderDetail from '../order/OrderDetail.vue'

export default {
    components: {
        VueGoodTable,VBToggle,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, OrderDetail, BSidebar, BLink
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [

                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                {
                    label: 'Customer Name',
                    field: 'customerName',
                },
                {
                    label: 'Customer Mobile No',
                    field: 'customerMobileNo',
                },
            ],
            rows: [],
            searchTerm: '',

        }
    },
    methods: {
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            let self = this;

            self.getOrderFilterList(text);
        },
        getOrderFilterList(orderNo) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersFilteredListByOrderNoContaining/' + orderNo, data))
                .then(function (response) {
                    if (response.data != '') {
                        self.rows = response.data;
                    } else {
                        self.rows = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderDetail(orderId) {
            let self = this;
            self.$refs.orderDetail.getOrderDetail(orderId);
        },

    },
    computed: {

    },
    created() {

    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
