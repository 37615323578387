<template>
    <b-card title="Order Item Delivering List">
        <b-row>
            <b-col md="4">
                <b-form-group label="Order Item Barcode">
                    <b-form-input v-model="orderItemPeiceBarcode"
                        placeholder="Please Enter Order Item Peice Barcode"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-button class="mt-2" @click="addByBarcode">Add</b-button>
            </b-col>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table @on-selected-rows-change="selectionChanged" :line-numbers="true" :columns="columns"
                    :rows="rows" :rtl="direction" :search-options="{
                        enabled: true,
                        externalQuery: searchTerm
                    }" :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: bookingDate -->
                        <span v-if="props.column.field === 'bookingDate'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ convertTimeStampToDateLocal(props.row.bookingDate) }}
                            </span>
                        </span>

                        <!-- Column: dueDate -->
                        <span v-else-if="props.column.field === 'dueDate'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ convertTimeStampToDate(props.row.dueDate) }}
                            </span>
                        </span>

                        <!-- Column: customerName -->
                        <span v-else-if="props.column.field === 'customerName'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ props.row.customerName }}
                            </span>
                            <span class="text-nowrap">
                                {{ '['+props.row.customerMobileNo + ']' }}
                            </span>
                            <span class="text-nowrap">
                                {{ props.row.address }}
                            </span>
                        </span>

                        <span v-else-if="props.column.field === 'brands'" class="text-nowrap">
                            <span v-for="brandName in props.row.brands" :key="brandName" class="text-nowrap">
                                {{ brandName.name + ', ' }}
                            </span>
                        </span>

                        <span v-else-if="props.column.field === 'colors'" class="text-nowrap">
                            <span v-for="colorsName in props.row.colors" :key="colorsName" class="text-nowrap">
                                {{ colorsName.name + ', ' }}
                            </span>
                        </span>

                        <span v-else-if="props.column.field === 'garmentRemarks'" class="text-nowrap">
                            <span v-for="garmentRemarksName in props.row.garmentRemarks" :key="garmentRemarksName"
                                class="text-nowrap">
                                {{ garmentRemarksName.name + ', ' }}
                            </span>
                        </span>

                        <span v-else-if="props.column.field === 'topUpServices'" class="text-nowrap">
                            <span v-for="topUpServicesName in props.row.topUpServices" :key="topUpServicesName"
                                class="text-nowrap">
                                {{ topUpServicesName.name + ', ' }}
                            </span>
                        </span>



                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </b-row>
        <hr>
        <b-row>
            <b-col class="text-center">
                <b-button @click="updateOrderItemDeliverStatus">Deliver Items</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import {
    BCard, BCol, BRow, BPagination, BFormSelect, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import moment from 'moment'

export default {
    components: {
        BCard, BCol, BRow, VueGoodTable, BPagination, BFormSelect, BFormGroup, BFormInput, BButton
    },
    data() {
        return {
            orderId: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Order No',
                    field: 'orderNo'
                },
                {
                    label: 'Qr Code',
                    field: 'qrCode',
                },
                {
                    label: 'Customer Details',
                    field: 'customerName'
                },
                {
                    label: 'ItemName',
                    field: 'itemName',
                },
                {
                    label: 'Booking Date',
                    field: 'bookingDate'
                },
                {
                    label: 'Due Date',
                    field: 'dueDate',
                },
                {
                    label: 'Garment Status',
                    field: 'garmentProcessStatus'
                },
                {
                    label: 'Services',
                    field: 'service.name',
                    width: '200px'
                },
                {
                    label: 'Brands',
                    field: 'brands'
                },
                {
                    label: 'Colors',
                    field: 'colors'
                },
                {
                    label: 'Garment Remarks',
                    field: 'garmentRemarks'
                },
                {
                    label: 'TopUpServices',
                    field: 'topUpServices',
                },
            ],
            rows: [],
            searchTerm: '',
            orderItemPieceList: [],
            orderItemPeiceBarcode: '',

        }
    },
    props: {
        getOrderDetail: Function
    },
    methods: {

        convertTimeStampToDate(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        getOrderItemDeliveList(orderItemIdList, orderId) {
            this.orderId = orderId;
            let self = this;
            var axios = require('axios');
            var data = orderItemIdList;
            axios(api.getApi('POST', '/orders/getOrderItemPicesListToDeliver/' + orderId, data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectionChanged(params) {
            this.orderItemPieceList = params.selectedRows;
        },

        updateOrderItemDeliverStatus() {
            const orderDeliverItemsList = [];
            let self = this;
            for (let index = 0; index < this.orderItemPieceList.length; index++) {
                orderDeliverItemsList.push(this.orderItemPieceList[index].id);
            }
            var axios = require('axios');
            var data = JSON.stringify(orderDeliverItemsList);
            axios(api.getApi('PUT', '/orders/updateOrderItemPicesDeliverStatus/' + self.orderId, data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    self.$swal({
                        title: '',
                        text: "Order Items Delivered Successfully.",
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    self.getOrderDetail(self.orderId);
                })
                .catch(function (error) {
                    console.log(error);
                });


        },
        addByBarcode() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrderItemPicesToDeliver/' + self.orderId + ',' + self.orderItemPeiceBarcode, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    self.rows.push(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
