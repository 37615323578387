<template>
    <b-sidebar id="sidebar-right" right shadow bg-variant="white" backdrop>
        <template #default="{ hide }">
            <b-card title="Order Payment">
                <b-form>
                    <b-row>
                        <b-tabs>
                            <b-tab active title="Accept Payment">

                                <b-row>
                                    <b-col md="12">
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Payment Mode *">
                                                    <v-select v-model="paymentModeType"
                                                        placeholder="Select Payment Mode"
                                                        :options="paymentModeTypeOptions"
                                                        @input="getCustomerDetailsWalletBalance"></v-select>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="paymentModeType == 'E_WALLET'">
                                            <b-col>
                                                <b-form-group label="Wallet Balance">
                                                    <h5>{{ walletBalance }}</h5>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Payment Description">
                                                    <b-form-input v-model="paymentDescription"
                                                        placeholder="Enter Payment Description"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Due Amount">
                                                    <h5>{{ totalDueAmount }}</h5>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Amount *">
                                                    <cleave v-model="amount"
                                                        @input="checkAmountWithDueAmountAndWalletAmount"
                                                        class="form-control" :raw="true" :options="options.number"
                                                        placeholder="Enter Amount" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Description">
                                                    <b-form-input v-model="transactionDescription"
                                                        placeholder="Enter Description"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="text-center">
                                                <b-button variant="primary" v-on:click="payOrderPayment"
                                                    @click="hide">Pay</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Adjust Payment">
                                <b-row>
                                    <b-col md="12">
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Payment Adjustment Mode *">
                                                    <v-select :options="paymentAdjustModeOptions"
                                                        v-model="paymentAdjustMode" placeholder="Select Adjustment Mode"
                                                        label="paymentSettlement"></v-select>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Due Amount">
                                                    <h5>{{ totalDueAmount }}</h5>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Adjust Amount *">
                                                    <cleave v-model="adjustAmount" @input="checkAdjustmentAmount"
                                                        class="form-control" :raw="true" :options="options.number"
                                                        placeholder="Enter Amount" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-group label="Adjustment Details">
                                                    <b-form-input v-model="adjustmentDetails"
                                                        placeholder="Enter Adjustment Details"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="text-center">
                                                <b-button variant="primary" v-on:click="adjustPaymentSubmit"
                                                    @click="hide">Adjust</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>

                    </b-row>
                </b-form>
            </b-card>
        </template>
    </b-sidebar>
</template>
<script>
import {
    BCard, BForm, BSidebar, BRow, BCol, BTab, BTabs, BFormInput, BCardText, BFormGroup, BButton, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import api from '@/store/api'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BCard, BForm, BRow, BSidebar, BCol, BTab, BTabs, BFormInput, BCardText, BFormGroup, vSelect, BButton, BFormCheckbox, Cleave
    },
    data() {
        return {
            isByWallet: false,
            walletBalance: 0,
            paymentDescription: '',
            paymentModeTypeOptions: [],
            paymentModeType: 'CASH',
            dueAmount: 0,
            payAmount: 0,
            amount: 0,
            paymentAdjustMode: '',
            paymentAdjustModeOptions: [],
            transactionDescription: '',
            adjustAmount: 0,
            adjustmentDetails: '',
            options: {
                number: {
                    numeral: true,
                },
            },
            defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration'))
        }
    },
    props: {
        walletId: Number,
        customerId: Number,
        totalDueAmount: Number,
        orderId: Number,
        getOrderDetail: Function
    },
    methods: {
        getPaymentMode() {
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/paymentModeTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.paymentModeTypeOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getPaymentAdjustmentTypes() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/paymentAdjustmentTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.paymentAdjustModeOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        payOrderPayment() {
            let self = this;
            if (self.paymentModeType != '' && self.amount > 0) {
                let walletId = 0;
                if (self.paymentModeType == 'E_WALLET') {
                    if (self.walletBalance > 0) {
                        walletId = self.walletId;
                    } else {
                        walletId = 0;
                    }

                } else {
                    walletId = 0;
                }
                var axios = require('axios');
                var data = JSON.stringify(
                    {
                        "amount": self.amount,
                        "currency": self.defaultConfiguration.currency,
                        "orderId": self.orderId,
                        "paymentDescription": self.paymentDescription,
                        "paymentMode": self.paymentModeType,
                        "storeId": self.$store.state.storeId,
                        "transactionDescription": self.transactionDescription,
                        "transactionType": 'CREDIT',
                        "walletId": walletId
                    });
                axios(api.getApi('Post', '/finance/accounts/createTransaction', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Transaction Successfully Done!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                        self.getOrderDetail(self.orderId);

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                self.resetForm();

            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Payment Type And Amount.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        adjustPaymentSubmit() {
            let self = this;
            if(self.paymentAdjustMode!=null && self.adjustAmount>0){
            var axios = require('axios');

            var data = JSON.stringify(
                {
                    "adjustmentByEmpId":self.$store.state.empId,
                    "adjustmentAmount": self.adjustAmount,
                    "orderId": self.orderId,
                    "adjustmentDetails": self.adjustmentDetails,
                    "storeId": self.$store.state.storeId,
                    "paymentAdjustmentTypeId": self.paymentAdjustMode.id,
                });
            axios(api.getApi('Post', '/orders/createOrderPaymentAdjustment', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Payment Adjsutment Successfully Done!`,
                            icon: 'SaveIcon',
                            variant: 'success',
                        },
                    })
                    self.getOrderDetail(self.orderId);

                })
                .catch(function (error) {
                    console.log(error);
                });
            }else{
                this.$swal({
                            title: '',
                            text: "Please Enter Payment Adjustment Type And Amount!",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
            }
        },
        getCustomerDetailsWalletBalance() {
            let self = this;

            var axios = require('axios');
            var data = '';
            if (self.paymentModeType == 'E_WALLET') {
                axios(api.getApi('get', '/customersDetails/' + self.customerId, data))
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        self.walletBalance = response.data.walletBalance;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                self.walletBalance = 0;
            }

        },
        checkAmountWithDueAmountAndWalletAmount(amount) {
            if (this.paymentModeType == 'E_WALLET') {
                if (amount > this.walletBalance) {
                    this.$swal({
                        title: '',
                        text: "Amount Greater Than Wallet Amount!",
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if (result.value) {
                            this.amount = 0;
                        }
                    })
                } else {
                    if (amount > this.totalDueAmount) {
                        this.$swal({
                            title: '',
                            text: "Amount Greater Than Total Due Amount!",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if (result.value) {
                                this.amount = 0;
                            }
                        })
                    }
                }
            } else {
                if (amount > this.totalDueAmount) {
                    this.$swal({
                        title: '',
                        text: "Amount Greater Than Total Due Amount!",
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if (result.value) {
                            this.amount = 0;
                        }
                    })
                }
            }

        },

        checkAdjustmentAmount(adjustAmount) {
            if (adjustAmount > this.totalDueAmount) {
                this.$swal({
                    title: '',
                    text: "Amount Greater Than Total Due Amount!",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.adjustAmount = 0;
                    }
                })
            }
        },

        resetForm() {
            let self = this;
            self.paymentModeType = '';
            self.paymentDescription = '';
            self.dueAmount = '';
            self.walletBalance = 0;
            self.amount = 0;
            self.transactionDescription = '';
        }

    },

    created() {
        let self = this;
        self.getPaymentMode();
        self.amount=self.totalDueAmount;
        self.getPaymentAdjustmentTypes();
    }
}
</script>