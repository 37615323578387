<template>
    <b-card title="Order Detail">
        <b-form>
            <b-row>
                <b-col md="8">
                    <b-row>
                        <b-col md="12">
                            <b-form-group label="Search By Order No">
                                <v-select :filterable="false" v-model="searchBoxForOrderNo" placeholder="Enter Order No"
                                    :options="customersOrderNo" @search="onInputChangeOnInvoice" @input="onSelectedOrderNo">
                                    <template #option="{ orderId, orderNo, customerName, customerId, customerMobileNo }">
                                        <h4># {{ orderNo }}</h4>
                                        <span>{{ customerName }} {{ '[' + customerMobileNo + ']' }} </span>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <!-- <b-col md="3">
                                <b-form-group label="Cloth By Barcode">
                                    <b-form-input placeholder="Enter Cloth Barcode"></b-form-input>
                                </b-form-group>
                            </b-col> -->
                        <!-- <b-col md="2">
                                <b-form-group label="Total">
                                    <b-form-input v-model="total"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group label="Delivered">
                                    <b-form-input v-model="delivered"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group label="Selected">
                                    <b-form-input v-model="selected"></b-form-input>
                                </b-form-group>
                            </b-col> -->


                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="12">
                            <div>
                                <!-- table -->
                                <vue-good-table @on-selected-rows-change="selectionChanged" :columns="columns" :rows="rows"
                                    :rtl="direction" :search-options="{
                                        enabled: true,
                                        externalQuery: searchTerm
                                    }" :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                                    <template slot="table-row" slot-scope="props">

                                        <!-- Column: Item Image -->
                                        <span v-if="props.column.field === 'itemPic'" class="text-nowrap">
                                            <img v-if="props.row.itemPic != ''" :src=convertImage(props.row.itemPic)
                                                height="70px" />
                                        </span>

                                        <span v-else-if="props.column.field === 'itemName'">
                                            <span class="text-nowrap">{{ props.row.itemName }}</span><br>
                                            <span class="text-nowrap attributes-font">{{ '-Qty' + props.row.quantity }}</span>
                                            <span class="text-nowrap attributes-font">{{ '-Pic' + props.row.pieceCount
                                            }}</span>
                                        </span>

                                        <span v-else-if="props.column.field === 'serviceName'">
                                            <span class="text-nowrap">{{
                                                props.row.serviceName 
                                            }}</span><br>
                                            <span class="text-nowrap">{{
                                                'Rs '+ props.row.totalAmount
                                            }}</span>
                                        </span>

                                        <!-- Column: Ready On -->
                                        <span v-else-if="props.column.field === 'attributes'">
                                            <span v-for="topUpServicesName in props.row.topUpServices"
                                                :key="topUpServicesName" class="text-nowrap attributes-font">
                                                {{
                                                    topUpServicesName.name + ', '
                                                }}
                                            </span>
                                            <span v-if="props.row.totalTupUpServiceAmount > 0"
                                                class="text-nowrap attributes-font">
                                                {{ '-' + props.row.totalTupUpServiceAmount }}
                                            </span><br>
                                            <span v-for="brandName in props.row.brands" :key="brandName"
                                                class="text-nowrap attributes-font">
                                                {{ brandName.name + ', ' }}
                                            </span><br>
                                            <span v-for="garmentRemarksName in props.row.garmentRemarks"
                                                :key="garmentRemarksName" class="text-nowrap attributes-font">
                                                {{ garmentRemarksName.name + ', ' }}
                                            </span><br>
                                            <span v-for="colorsName in props.row.colors" :key="colorsName"
                                                class="text-nowrap attributes-font">
                                                {{ colorsName.name + ', ' }}
                                            </span>
                                        </span>

                                        <!-- Column: Ready On -->
                                        <span v-else-if="props.column.field === 'itemReadyOnDate'">
                                            <span class="text-nowrap">{{
                                                convertTimeStampToDateLocal(props.row.itemReadyOnDate)
                                            }}</span>
                                            <span class="text-nowrap">{{ props.row.itemReadyByEmpName }}</span>
                                        </span>

                                        <!-- Column: Ready On -->
                                        <span v-else-if="props.column.field === 'itemDeliveredOnDate'">
                                            <span class="text-nowrap">{{
                                                convertTimeStampToDateLocal(props.row.itemDeliveredOnDate)
                                            }}</span>
                                            <span class="text-nowrap">{{ props.row.itemDeleiveredByEmpName }}</span>
                                        </span>


                                        <!-- Column: Common -->
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>

                                    <!-- pagination -->
                                    <template slot="pagination-bottom" slot-scope="props">
                                        <div class="d-flex justify-content-between flex-wrap">
                                            <div class="d-flex align-items-center mb-0 mt-1">
                                                <span class="text-nowrap ">
                                                    Showing 1 to
                                                </span>
                                                <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                                    class="mx-1"
                                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                                            </div>
                                            <div>
                                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                                    first-number last-number align="right" prev-class="prev-item"
                                                    next-class="next-item" class="mt-1 mb-0"
                                                    @input="(value) => props.pageChanged({ currentPage: value })">
                                                    <template #prev-text>
                                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                                    </template>
                                                    <template #next-text>
                                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                                    </template>
                                                </b-pagination>
                                            </div>
                                        </div>
                                    </template>
                                </vue-good-table>
                            </div>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row v-if="orderStatus != 'CANCELED'">

                        <!-- <b-col md="12">
                                <b-form-textarea rows="2" placeholder="Notes..."></b-form-textarea>
                            </b-col> -->

                        <!-- <b-col md="3" class="mt-2">
                                <b-button v-if="orderId > 0" block>Packing Detail</b-button>
                            </b-col> -->
                        <b-col md="3" class="mt-2">
                            <b-button v-if="orderId > 0" v-b-toggle.sidebar-right-orderItemDeiveringList block
                                @click="getOrderItemDeliveList">Deliver</b-button>
                        </b-col>
                        <b-col md="3" class="mt-2">
                            <b-button v-if="orderId > 0" v-b-toggle.sidebar-right-orderItemReturnGarmentList block
                                @click="getOrderItemReturnGarmentList">Return Garments</b-button>
                        </b-col>
                        <b-col md="3" class="mt-2">
                            <b-button block v-if="customerId > 0"
                                :to="{ name: 'customerDetail', params: { id: customerId } }">Customer
                                Details</b-button>
                        </b-col>


                        <b-col md="3" class="mt-2">
                            <b-button disabled block>SMS Notify</b-button>
                        </b-col>
                        <b-col md="3" class="mt-2">
                            <b-button disabled block>Email Notify</b-button>
                        </b-col>
                        <!-- <b-col md="3" class="mt-2">
                                <b-button block>Print Status</b-button>
                            </b-col> -->
                        <b-col md="3" class="mt-2">
                            <b-button v-if="orderId > 0" v-b-toggle.sidebar-right-printQRTags @click="printQrCodeList"
                                block>Print Tag</b-button>
                        </b-col>
                        <b-col md="3" class="mt-2">
                            <b-button v-if="orderId > 0" v-b-toggle.sidebar-right-orderReceipt @click="printOrderReceipt"
                                block>Print Receipt</b-button>
                        </b-col>



                    </b-row>
                </b-col>
                <b-col md="4" class="border">
                    <b-row>
                        <b-col col="6">
                            <h3><b>#{{ orderNo }}</b></h3>
                        </b-col>
                        <b-col col="6">
                            <h5>{{ orderCreateDate }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <!-- <h3><b>Order Status</b></h3> -->
                        </b-col>
                        <b-col col="6">
                            <h5><b>{{ orderStatus }}</b></h5>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <h4>{{ customerNameId }}</h4>
                            <h3><b>{{ customerName }}</b></h3>
                            <h5>{{ customerMobileNo }}</h5>
                            <h6>{{ customerAddress }}</h6>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col col="6">
                            <h5>Due Date</h5>
                        </b-col>
                        <b-col col="6">
                            <h5>{{ dueDate }}</h5>
                        </b-col>
                    </b-row>
                    <!-- <b-row>
                            <b-col class="text-center">
                                <b-button disabled variant="outline-primary" block>Last Visit Date {{
                                    lastVisitDate
                                }}</b-button>
                            </b-col>
                        </b-row> -->
                    <hr>
                    <b-row>
                        <b-col col="6">
                            <h6 class="label-font">Store Name</h6>
                        </b-col>
                        <b-col col="6">
                            <h4 class="value-font">{{ storeName }}</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h6 class="label-font">Order Created By</h6>
                        </b-col>
                        <b-col col="6">
                            <h5 class="value-font">{{ orderCreateBy }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h6 class="label-font">Garment Inspected By</h6>
                        </b-col>
                        <b-col col="6">
                            <h5 class="value-font">{{ garmentInspectedby }}</h5>
                        </b-col>
                    </b-row>
                    <b-row v-if="orderRemark">
                        <b-col col="6">
                            <h5 class="label-font">Order Remark</h5>
                        </b-col>
                        <b-col col="6">
                            <h4 class="value-font">{{ orderRemark }}</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h5 class="label-font">Total Quantity</h5>
                        </b-col>
                        <b-col col="6">
                            <h4 class="value-font">{{ totalQuantity }}</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h5 class="label-font">Total Pieces</h5>
                        </b-col>
                        <b-col col="6">
                            <h4 class="value-font">{{ totalPieces }}</h4>
                        </b-col>
                    </b-row>
                    <b-row v-if="isUrgent1Order != 'No'">
                        <b-col col="6">
                            <h5 class="label-font">Urgent Order</h5>
                        </b-col>
                        <b-col col="6">
                            <h4 class="value-font">{{ isUrgent1Order }}</h4>
                        </b-col>
                    </b-row>
                    <b-row v-if="isUrgent2Order != 'No'">
                        <b-col col="6">
                            <h5 class="label-font">Urgent Order</h5>
                        </b-col>
                        <b-col col="6">
                            <h4 class="value-font">{{ isUrgent2Order }}</h4>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col col="6">
                            <h6>Discount</h6>
                        </b-col>
                        <b-col col="6">
                            {{ discountAmount }}
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col col="6">
                            <h6>Gross</h6>
                        </b-col>
                        <b-col col="6">
                            {{ totalGrossAmount }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h6>Tax</h6>
                        </b-col>
                        <b-col col="6">
                            {{ totalTaxAmount }}
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col col="6">
                            <h6>Net</h6>
                        </b-col>
                        <b-col col="6">
                            {{ totalNetAmount }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h6>Adjusted Amount</h6>
                        </b-col>
                        <b-col col="6">
                            {{ totalAdjustmentAmount }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h6>Paid</h6>
                        </b-col>
                        <b-col col="6">
                            {{ totalPayedAmount }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="6">
                            <h6>Balance Due</h6>
                        </b-col>
                        <b-col col="6" class="border-top border-bottom">
                            <h4>{{ totalDueAmount }}</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col col="8">
                            <h6>Previous Invoice Due</h6>
                        </b-col>
                        <b-col col="4" class="border-top border-bottom">
                            <h4>{{ previousInvoiceDueAmount }}</h4>
                        </b-col>
                    </b-row>
                    <b-row v-if="orderStatus != 'CANCELED'">
                        <b-col v-if="orderId > 0">
                            <b-button block variant="success" @click="getOrderPaymentHistory()"
                                v-b-toggle.sidebar-right-paymentHistory>Payment History</b-button>
                        </b-col>
                    </b-row>
                    <b-row v-if="totalDueAmount > 0">
                        <b-col class="p-1" v-if="orderStatus != 'CANCELED'">
                            <!-- <b-button block variant="secondary">Resend Payment Link</b-button> -->
                            <b-button block variant="primary" v-b-toggle.sidebar-right>Accept Payment</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-form>

        <b-sidebar id="sidebar-right-paymentHistory" width="90%" right shadow bg-variant="white" backdrop>
            <order-payment-history ref="orderPaymentHistory"></order-payment-history>
        </b-sidebar>
        <!-- <b-sidebar v-if="totalDueAmount > 0" id="sidebar-right" right shadow bg-variant="white" backdrop> -->
        <div v-if="totalDueAmount > 0">
            <order-payment :walletId="walletId" :customerId="customerId" :totalDueAmount="totalDueAmount" :orderId="orderId"
                :getOrderDetail="getOrderDetail"></order-payment>
        </div>
        <!-- </b-sidebar> -->

        <b-sidebar v-if="selectedItems > 0" id="sidebar-right-orderItemDeiveringList" width="90%" right shadow
            bg-variant="white" backdrop>
            <order-item-deliver-list ref="orderItemDeliveringList" :getOrderDetail=getOrderDetail></order-item-deliver-list>
        </b-sidebar>

        <b-sidebar v-if="selectedItems > 0" id="sidebar-right-orderItemReturnGarmentList" width="90%" right shadow
            bg-variant="white" backdrop>
            <order-item-return-garment-list ref="orderItemReturnGarmentList"
                :getOrderDetail=getOrderDetail></order-item-return-garment-list>
        </b-sidebar>

        <b-sidebar v-if="orderId > 0" width="80%" id="sidebar-right-printQRTags" right shadow bg-variant="white" backdrop>
            <PrintQRTags ref="printQRTags"></PrintQRTags>
        </b-sidebar>
        <b-sidebar v-if="orderId > 0" id="sidebar-right-orderReceipt" right shadow bg-variant="white" backdrop>
            <OrderReceipt ref="orderReceipt"></OrderReceipt>
        </b-sidebar>

        <AutoOrderReceipt ref="autoOrderReceipt" v-if="autoPrint && orderId > 0 && defaultConfiguration.orderConfiguration.autoPrintInvoiceOnOrderGeneration"></AutoOrderReceipt>
    
        <AutoQRCodeTagGenerator ref="autoQrCodeTagPrint" v-if="autoPrint && orderId > 0 && defaultConfiguration.orderConfiguration.autoPrintGarmentTagOnOrderGeneration"></AutoQRCodeTagGenerator>
    </b-card>
</template>

<script>
import {
    BCard, BCol, VBToggle, BRow, BForm, BSidebar, BFormGroup, BFormInput, BButton, BFormTextarea, BAvatar, BPagination, BFormSelect
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import util from '@/store/utils'
import OrderPayment from './OrderPayment.vue'
import vSelect from 'vue-select'
import OrderPaymentHistory from './OrderPaymentHistory.vue'
import OrderItemDeliverList from './OrderItemDeliverList.vue'
import OrderItemReturnGarmentList from './OrderItemReturnGarmentList.vue'
import PrintQRTags from '../process/PrintQRTags.vue'
import OrderReceipt from '../process/OrderReceipt.vue'

import AutoOrderReceipt from './AutoOrderReceipt.vue'
import AutoQRCodeTagGenerator from './AutoQRCodeTagGenerator.vue'

export default {
    components: {
        BCard, BCol, VBToggle, BRow, BForm, BSidebar, BFormGroup, BFormInput, BButton, OrderItemDeliverList,
        BFormTextarea, BAvatar, BPagination, BFormSelect, VueGoodTable, OrderPayment, vSelect, OrderPaymentHistory,
        OrderItemReturnGarmentList, PrintQRTags, OrderReceipt, AutoOrderReceipt, AutoQRCodeTagGenerator
    },
    data() {
        return {
            autoPrint:false,
            searchBoxForOrderNo: '',
            customersOrderNo: [],
            orderId: 0,
            total: 0,
            selected: 0,
            delivered: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Item Image',
                    field: 'itemPic',
                },
                {
                    label: 'Garment Details',
                    field: 'itemName',
                },
                {
                    label: 'Qr Code',
                    field: 'orderItemBarcode',
                },
                {
                    label: 'Service [Price]',
                    field: 'serviceName',
                },
                {
                    label: 'Attributes',
                    field: 'attributes',
                },
                {
                    label: 'Item Status',
                    field: 'orderItemStatus',
                },
                // {
                //     label: 'Ready On',
                //     field: 'itemReadyOnDate',
                // },
                // {
                //     label: 'Delivered On',
                //     field: 'itemDeliveredOnDate'
                // }
            ],
            rows: [],
            searchTerm: '',
            orderNo: '',
            orderCreateDate: '',
            customerId: '',
            customerNameId: 0,
            customerName: '',
            customerMobileNo: '',
            customerAddress: '',
            dueDate: '',
            lastVisitDate: '',
            storeName: '',
            storeId:0,
            orderCreateBy: '',
            garmentInspectedby: '',
            orderRemark: '',
            totalQuantity: 0,
            totalPieces: 0,
            discountAmount: 0,
            totalGrossAmount: 0,
            totalTaxAmount: 0,
            totalNetAmount: 0,
            totalPayedAmount: 0,
            totalDueAmount: 0,
            totalAdjustmentAmount: 0,
            walletId: 0,
            orderItemList: [],
            selectedItems: 0,
            orderReceiptList: [],
            storeDataList: [],
            termsAndCondition: [],
            orderStatus: '',
            isUrgent1Order: 'No',
            isUrgent2Order: 'No',
            previousInvoiceDueAmount: 0,
            defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration')),

            orderItemsPiecesList:[],
            garmentProcessStatus:''

        }
    },
    directives: {
        'b-toggle': VBToggle,
    },
    methods: {
        convertImage(photo) {
            if (photo == null) {
                return null;
            } else {
                return 'data:image/*;base64,' + photo;
            }

        },
        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },
        getOrderDetail(orderId) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersDetailById/' + orderId, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));

                    self.orderReceiptList = response.data;
                    self.orderStatus = response.data.orderStatus;
                    self.orderNo = response.data.orderNo;
                    self.orderCreateDate = util.convertTimeStampToDateLocal(response.data.orderCreateDate);
                    self.customerId = response.data.customerId;
                    if (util.digits_count(response.data.customerId) == 3) {
                        self.customerNameId = "0" + response.data.customerId;
                    } else if (util.digits_count(response.data.customerId) == 2) {
                        self.customerNameId = "00" + response.data.customerId;
                    }
                    else if (util.digits_count(response.data.customerId) == 1) {
                        self.customerNameId = "000" + response.data.customerId;
                    }
                    self.customerName = response.data.customerName;
                    self.customerMobileNo = response.data.customerMobileNo;
                    self.customerAddress = response.data.customerAddress;
                    self.dueDate = util.convertTimeStampToDate(response.data.dueDate);
                    self.lastVisitDate = util.convertTimeStampToDateLocal(response.data.lastVisitDate);
                    self.storeName = response.data.storeName;
                    self.storeId = response.data.storeId;
                    self.orderCreateBy = response.data.orderCreatedBy;
                    self.garmentInspectedby = response.data.garmentInspectedBy;
                    self.orderRemark = response.data.orderRemark;
                    self.totalQuantity = response.data.totalItems;
                    self.totalPieces = response.data.totalPices;
                    self.discountAmount = response.data.discountAmount;
                    self.totalGrossAmount = response.data.totalGrossAmount;
                    self.totalTaxAmount = response.data.totalTaxAmount;
                    self.totalNetAmount = response.data.totalNetAmount;
                    self.totalPayedAmount = response.data.totalPayedAmount;
                    self.totalAdjustmentAmount = response.data.totalAdjustmentAmount
                    //self.totalDueAmount = response.data.totalNetAmount-response.data.totalPayedAmount-response.data.totalAdjustmentAmount;
                    self.totalDueAmount = response.data.totalDueAmount;
                    self.rows = response.data.orderItems;

                    self.walletId = response.data.walletId;

                    if (response.data.urgent1) {
                        self.isUrgent1Order = 'Yes' + ' (Urgent 1)'
                    } else {
                        self.isUrgent1Order = 'No';
                    }

                    if (response.data.urgent2) {
                        self.isUrgent2Order = 'Yes' + ' (Urgent 2)'
                    } else {
                        self.isUrgent2Order = 'No';
                    }

                    self.previousInvoiceDueAmount = response.data.previousDueAmount;

                    self.orderId = orderId;

                    self.getStoreDetails();

                   
                    

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onSelectedOrderNo(option) {
            // this.searchBoxForOrderNo = option.item;
            this.orderId = option.orderId;
            this.searchBoxForOrderNo = option.orderNo;
            this.getOrderDetail(option.orderId);
        },

        onInputChangeOnInvoice(orderNo) {
            if (orderNo === '' || orderNo === undefined) {
                return
            }
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersFilteredListByOrderNoContaining/' + orderNo, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersOrderNo = response.data;
                    } else {
                        self.customersOrderNo = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderPaymentHistory() {
            this.$refs.orderPaymentHistory.getOrderDetail(this.orderId);
        },
        selectAll() {

        },
        selectionChanged(params) {
            if (params.selectedRows.length > 0) {
                this.selectedItems = 1;
            } else {
                this.selectedItems = 0;
            }
            this.orderItemList = params.selectedRows;
        },
        getOrderItemDeliveList() {
            const orderItems = [];

            for (let index = 0; index < this.orderItemList.length; index++) {
                orderItems.push(this.orderItemList[index].id);
            }
            var data = JSON.stringify(orderItems);
            if (orderItems.length > 0) {
                this.$refs.orderItemDeliveringList.getOrderItemDeliveList(data, this.orderId)
            } else {
                this.$swal({
                    title: '',
                    text: "Select Order Item.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },
        getOrderItemReturnGarmentList() {
            const orderItems = [];

            for (let index = 0; index < this.orderItemList.length; index++) {
                orderItems.push(this.orderItemList[index].id);
            }
            var data = JSON.stringify(orderItems);
            if (orderItems.length > 0) {
                this.$refs.orderItemReturnGarmentList.getOrderItemReturnList(data, this.orderId)
            } else {
                this.$swal({
                    title: '',
                    text: "Select Order Item.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        printQrCodeList() {
            let self = this;
            self.$refs.printQRTags.getQRCodeDetails(self.orderId, false);
        },
        printOrderReceipt() {
            let self = this;
            self.$refs.orderReceipt.setOrderReceipt(self.orderReceiptList, self.storeDataList, self.termsAndCondition);
        },
        autoPrintOrderReceipt() {
            let self = this;
            self.$refs.autoOrderReceipt.setOrderReceipt(self.orderReceiptList, self.storeDataList, self.termsAndCondition);

        },
        autoQrCodeTagPrint(){
            // let self=this;
            this.$refs.autoQrCodeTagPrint.setQrCodeTagDesign(
                    this.defaultConfiguration.barcodeConfiguration.barCodeWidth,
                    this.defaultConfiguration.barcodeConfiguration.barCOdeHeight,
                    this.defaultConfiguration.barcodeConfiguration.barCodeAlign,
                    this.defaultConfiguration.barcodeConfiguration.printLogo,
                    this.defaultConfiguration.barcodeConfiguration.printBookingNo,
                    this.defaultConfiguration.barcodeConfiguration.qrcode,
                    this.defaultConfiguration.barcodeConfiguration.printCustomerName,
                    this.defaultConfiguration.barcodeConfiguration.printAddress,
                    this.defaultConfiguration.barcodeConfiguration.printProcess,
                    this.defaultConfiguration.barcodeConfiguration.printItemTotal,
                    this.defaultConfiguration.barcodeConfiguration.printRemark,
                    this.defaultConfiguration.barcodeConfiguration.printColour,
                    this.defaultConfiguration.barcodeConfiguration.printBookingDate,
                    this.defaultConfiguration.barcodeConfiguration.printOrderTime,
                    this.defaultConfiguration.barcodeConfiguration.printDueDate,
                    this.defaultConfiguration.barcodeConfiguration.printItemName,
                    this.defaultConfiguration.barcodeConfiguration.printStoreName,
                    this.orderItemsPiecesList, this.storeName);
        },
        getStoreDetails() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores/' + self.$store.state.storeId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeDataList = response.data;
                    self.getTermsAndConditions();

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTermsAndConditions() {
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/storeReceiptTermAndConditions', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.termsAndCondition = response.data;
                    if (self.defaultConfiguration.orderConfiguration.autoPrintInvoiceOnOrderGeneration && self.autoPrint) {
                        self.autoPrintOrderReceipt();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getQRCodeDetailsList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            let url = '/orders/getOrderItemPicesDetiledList?storeId=' + self.storeId + "&orderId=" + self.orderId + "&garmentProcessStatus=" + self.garmentProcessStatus;
            axios(api.getApi('get', url, data))
                .then(function (response) {
                    if (response.data != null) {
                        self.orderItemsPiecesList = response.data;
                        self.autoQrCodeTagPrint();
                    } else {
                        self.orderItemsPiecesList = [];
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },

    created() {
        this.orderId = this.$route.params.orderId;
        if (this.orderId > 0) {

            this.getOrderDetail(this.orderId);
            this.autoPrint=true;
            if(this.defaultConfiguration.orderConfiguration.autoPrintGarmentTagOnOrderGeneration){
                this.getQRCodeDetailsList();
            }
            
        } else {
        }
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.attributes-font {
    font-size: 12px;
}

.label-font {
    font-size: 14px;
}

.value-font {
    font-size: 12px;
}
</style>
