<template>
    <b-card title="Order Payment History">
        <b-tabs>
            <b-tab active title="Payment History">
                <b-row>
                    <div>
                        <!-- search input -->
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="d-flex align-items-center">
                                    <label class="mr-1">Search</label>
                                    <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                        class="d-inline-block" />
                                </div>
                            </b-form-group>
                        </div>

                        <!-- table -->
                        <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                            :search-options="{
                                enabled: true,
                                externalQuery: searchTerm
                            }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                            <template slot="table-row" slot-scope="props">

                                <!-- Column: lastModifiedDate -->
                                <span v-if="props.column.field === 'lastModifiedDate'" class="text-nowrap">
                                    <span class="text-nowrap">
                                        {{ convertTimeStampToDateWithTime(props.row.lastModifiedDate) }}
                                        <!-- {{props.row.lastUpdated}} -->
                                    </span>
                                </span>

                                <!-- Column: Credit / Debit -->
                                <span v-else-if="props.column.field === 'transactionType'" class="text-nowrap">
                                    <span v-if="props.row.transactionType == 'CREDIT'" class="text-nowrap">
                                        CR
                                    </span>
                                    <span v-else-if="props.row.transactionType == 'DEBIT'" class="text-nowrap">
                                        DR
                                    </span>
                                </span>

                                <!-- Column: amount -->
                                <span v-else-if="props.column.field === 'amount'" class="text-nowrap">
                                    <span v-if="props.row.transactionType == 'CREDIT'" class="text-nowrap amountCredit">
                                        {{ props.row.amount }}
                                    </span>
                                    <span v-else-if="props.row.transactionType == 'DEBIT'"
                                        class="text-nowrap amountDebit">
                                        {{ props.row.amount }}
                                    </span>
                                </span>

                                <!-- Column: updatedBy -->
                                <span v-else-if="props.column.field === 'updatedBy'" class="text-nowrap">
                                    <span class="text-nowrap">
                                        {{ props.row.updatedBy.fullName }}
                                    </span><br>
                                    <span class="text-nowrap">
                                        [ {{ props.row.updatedBy.mobileNo }} ]
                                    </span>
                                </span>

                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                            class="mx-1"
                                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                            first-number last-number align="right" prev-class="prev-item"
                                            next-class="next-item" class="mt-1 mb-0"
                                            @input="(value) => props.pageChanged({ currentPage: value })">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-row>
            </b-tab>
            <b-tab title="Payment Adjustment">
                <b-row>
                    <b-col md="12">
                    <div>
                        <!-- search input -->
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="d-flex align-items-center">
                                    <label class="mr-1">Search</label>
                                    <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                        class="d-inline-block" />
                                </div>
                            </b-form-group>
                        </div>

                        <!-- table -->
                        <vue-good-table :line-numbers="true" :columns="adjustmentColumns" :rows="adjustmentRows" :rtl="direction"
                            :search-options="{
                                enabled: true,
                                externalQuery: adjustmentSearchTerm
                            }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                            <template slot="table-row" slot-scope="props">

                                <!-- Column: lastModifiedDate -->
                                <span v-if="props.column.field === 'adjustmentDate'" class="text-nowrap">
                                    <span class="text-nowrap">
                                        {{ convertTimeStampToDateWithTime(props.row.adjustmentDate) }}
                                        <!-- {{props.row.lastUpdated}} -->
                                    </span>
                                </span>
                                

                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                            class="mx-1"
                                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                            first-number last-number align="right" prev-class="prev-item"
                                            next-class="next-item" class="mt-1 mb-0"
                                            @input="(value) => props.pageChanged({ currentPage: value })">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-col>
                </b-row>
            </b-tab>
        </b-tabs>

    </b-card>
</template>
<script>
import {
    BCard, BCol, BRow, BPagination, BFormSelect, BFormGroup, BFormInput, BTabs, BTab
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import moment from 'moment'

export default {
    components: {
        BCard, BCol, BRow, VueGoodTable, BPagination, BFormSelect, BFormGroup, BFormInput, BTabs, BTab
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'storeName'
                },
                {
                    label: 'Wallet Id',
                    field: 'walletId',
                },
                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                // {
                //     label: 'Currency',
                //     field: 'currency'
                // },
                {
                    label: 'Cr. / Dr.',
                    field: 'transactionType',
                    width: '100px'
                },
                {
                    label: 'Amount',
                    field: 'amount'
                },
                {
                    label: 'Transaction Status',
                    field: 'transactionStatus'
                },
                {
                    label: 'Payment Mode',
                    field: 'paymentMode'
                },
                {
                    label: 'Payment Description',
                    field: 'paymentDescription'
                },
                {
                    label: 'Transaction Description',
                    field: 'transactionDescription'
                },
                {
                    label: 'Last Updated',
                    field: 'lastModifiedDate',
                },
                {
                    label: 'Last Updated By',
                    field: 'lastModifiedByUserName',
                    width: '300px'
                },
            ],
            rows: [],
            searchTerm: '',


            adjustmentColumns: [
                {
                    label: 'Store Name',
                    field: 'storeName'
                },
                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                {
                    label: 'Adjustment Type',
                    field: 'paymentAdjustmentType'
                },
                {
                    label: 'Amount',
                    field: 'adjustmentAmount'
                },
                {
                    label: 'Adjustment Details',
                    field: 'adjustmentDetails'
                },
                {
                    label: 'Last Updated',
                    field: 'adjustmentDate',
                },
                {
                    label: 'Last Updated By',
                    field: 'adjustmentByEmp',
                    width: '300px'
                },
            ],
            adjustmentRows: [],
            adjustmentSearchTerm: '',
        }
    },
    props: {
        //orderId:Number
    },
    methods: {
        convertTimeStampToDateWithTime(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },
        getOrderDetail(orderId) {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrderPaymentHistory/' + orderId, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data))
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

                self.getPaymentAdjustmentList(orderId);
        },
        
        getPaymentAdjustmentList(orderId){
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrderPaymentAdjustmentDetails?empId='+ 0 +'&storeId='+ 0 +'&orderId=' + orderId + '&paymentAdjutTypeId=' + 0, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data))
                    self.adjustmentRows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    }
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.amountCredit {
    color: green;
}

.amountDebit {
    color: red;
}
</style>