<template>
    <b-card>
        <b-row >
            <b-col class="text-center">
                <b-button @click="printQrTag">Print</b-button>
            </b-col>
           
        </b-row>
        <hr>
        <b-row id="tags">
            
            <div  v-for="order in orderItemsPiecesList" :key="order" class="border text-center tagsHeight">
                <br>
                <b-col md="12" v-if="logo">
                    <img width="30px" :src="require('@/assets/images/logo/Apollo.png')" />
                </b-col>
                <b-col md="12" v-if="storeNamePrint">
                        <p >{{storeName}}</p>
                    </b-col>
                
                <b-col md=12 v-if="qrCode" class=" tag-margin-bottom">
                    <!-- <b-img v-bind="mainProps" blank-color="#475F7B" rounded alt="Design Tag QR" /> -->
                    <center>
                        <qrCodeTag size="100" color="#000" bg-color="#fff" error-level="H" :text=order.qrCode></qrCodeTag>
                    </center>
                        
                    
                </b-col>
                <b-col md="12" v-if="orderNo">
                    <h3 class="tag-margin-top tag-margin-bottom">{{'#'+order.qrCode}}</h3>
                </b-col>
                <b-row>
                    <b-col md="12" v-if="customerName">
                        <p class="tag-margin-top tag-margin-bottom">{{order.customerName}}{{ ' ['+order.customerMobileNo+']' }}</p>
                    </b-col>
                    <b-col md="12" v-if="address">
                        <p class="tag-margin-top tag-margin-bottom">{{order.address}}</p>
                    </b-col>
                    <b-col md="12" v-if="process">
                        <p class="tag-margin-top tag-margin-bottom">{{order.garmentProcessStatus}}</p>
                    </b-col>
                    <!-- <b-col md="12" v-if="itemTotal">
                        <p class="tag-margin-top tag-margin-bottom">Item Total</p>
                    </b-col> -->
                    <b-col  class="tag-margin-top tag-margin-bottom"  md="12" v-if="remark" >
                        <span v-for="remarks in order.garmentRemarks" :key="remarks">{{remarks.name+', ' }}</span>
                    </b-col>
                    <b-col  class="tag-margin-top tag-margin-bottom"  md="12" v-if="color" >
                        <span v-for="colorsName in order.colors" :key="colorsName">{{colorsName.name+', '}}</span>
                    </b-col>
                    <b-col md="12" v-if="orderDate">
                        <p class="tag-margin-top tag-margin-bottom">Order Date :{{convertTimeStampToDateWithTimeLocal(order.bookingDate)}}</p>
                    </b-col>
                    <!-- <b-col md="12" v-if="orderTime">
                        <p class="tag-margin-top tag-margin-bottom">Order Time</p>
                    </b-col> -->
                    <b-col md="12" v-if="dueDate">
                        <p class="tag-margin-top tag-margin-bottom">Due Date :{{convertTimeStampToDateWithTime(order.dueDate)}}</p>
                    </b-col>
                    <b-col md="12" v-if="itemName">
                        <p class="tag-margin-top tag-margin-bottom">{{order.itemName}}</p>
                    </b-col>
                    <!-- <b-col md="12">
                        <p class="tag-margin-top tag-margin-bottom">Designed By Kanwat Infotech Pvt. Ltd.</p>
                    </b-col> -->
                    <!-- <b-col style="border: 1px dashed;"></b-col> -->
                </b-row>
                
            </div>
        </b-row>
    </b-card>
</template>
<script>
import {
    BCard, BRow, BCol, BImg,BButton
} from 'bootstrap-vue'
import moment from 'moment'
import qrCodeTag from 'vue-qrcode-component'
export default {
    components: {
        BCard, BRow, BCol, BImg,BButton,qrCodeTag
    },
    data() {
        return {
            mainProps: {
                blank: true,
                width: 100,
                height: 100,
                class: 'm1',
            },
            value: 'https://example.com',
            size: 300,

            width: '0px',
            tagHeight:'',
            barCodeAlign:'center',

            logo: false,
            orderNo: false,
            qrCode: false,
            customerName: false,
            address: false,
            process: false,
            itemTotal: false,
            remark: false,
            color: false,
            orderDate: false,
            orderTime: false,
            dueDate: false,
            itemName: false,
            storeNamePrint: false,
            orderItemsPiecesList:[],
            storeName:'',

        }
    },
    methods: {
        convertTimeStampToDateWithTimeLocal(value){
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).local().format('DD-MM-YYYY hh:mm A');
            }
        },
        convertTimeStampToDateWithTime(value){
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).format('DD-MM-YYYY hh:mm A');
            }
        },
        setQrCodeTagDesign(width,height, barCodeAlign, printLogo, printOrderNo, printQrCode, printCustomerName, printAddress, printProcess, printItemTotal, printRemark, printColor, printOrderDate, printOrderTime, printDueDate, printItemName, printStoreName,orderItemsPieces,storeName) {
            this.orderItemsPiecesList=orderItemsPieces;
            this.storeName=storeName;
            this.width = width;
            this.tagHeight=height;
            this.barCodeAlign = barCodeAlign;
            this.logo = printLogo;
            this.orderNo = printOrderNo;
            this.qrCode = printQrCode;
            this.customerName = printCustomerName;
            this.address = printAddress;
            this.process = printProcess;
            this.itemTotal = printItemTotal;
            this.remark = printRemark;
            this.color = printColor;
            this.orderDate = printOrderDate;
            this.orderTime = printOrderTime;
            this.dueDate = printDueDate;
            this.itemName = printItemName;
            this.storeNamePrint = printStoreName;
        },
        printQrTag(){
            var divElement = document.querySelector(".tagsHeight");

            let elemHeight = divElement.offsetHeight;
            var divContents = document.getElementById("tags").innerHTML;  
                  var printWindow = window.open('','', '', '');  
                   printWindow.document.write('<html><head><title>'+this.storeName+'</title> <style> p { font-size:10dpi; } span{ font-size:10dpi; } h3{font-size:11dpi}  @page { size:'+this.width+ 'mm '+ (elemHeight) +'mm; margin: 0px; } .col-md-12  {margin-top: -20px; margin-bottom: 20px; text-align: center} </style>');  
                   printWindow.document.write('</head><body>');  
                   printWindow.document.write(divContents);  
                   printWindow.document.write('</body></html>');  
                   printWindow.document.close();  
                   printWindow.print();  
        }
    }
}
</script>
<style>
.tag-margin-top {
    margin-top: -20px;
}

.tag-margin-bottom {
    margin-bottom: 20px;
}
</style>