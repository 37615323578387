<template>
    <b-card title="Order Receipt">
        <b-row>
            <b-col class="text-center">
                <b-button @click="printReceipt">Print</b-button>
            </b-col>
        </b-row>
        <hr>
        <b-row id="receipt" class="receiptHeight">
            <div v-for="i in receiptCopy" :key="i">
                <div v-for="receipt in receiptDataList" :key="receipt">
                    <b-row>
                        <b-col>
                            <table>
                                <tr v-if="i == 1">
                                    <td>
                                        <p style="font-size: 10px; text-align: right; text-decoration: underline;">
                                            Customer Copy</p>
                                    </td>
                                </tr>
                                <tr v-if="i == 2">
                                    <td>
                                        <p style="font-size: 10px; text-align: right; text-decoration: underline;">Store
                                            Copy</p>
                                    </td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <table>
                                <tr>
                                    <td>
                                        <img width="40px" :src="require('@/assets/images/logo/Apollo.png')" />
                                    </td>
                                    <td>
                                        <h4 class="storeName">{{ storeName }}<h6 class="storeDetail"> {{ storeAddress }}
                                                <h6 class="storeDetail">{{ storeContactNo }}</h6>
                                            </h6>
                                        </h4>
                                    </td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <h6>SPEED SERVICE WITH QUALITY</h6>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tr v-if="defaultConfiguration.orderConfiguration.includeNameInOrderReceipt">
                                    <td>
                                        <h6>Name:</h6>
                                    </td>
                                    <td>
                                        <h6>{{ receipt.customerName }}</h6>
                                    </td>
                                </tr>
                                <tr v-if="defaultConfiguration.orderConfiguration.includeMobileInOrderReceipt">
                                    <td>
                                        <h6>MobileNo:</h6>
                                    </td>
                                    <td>
                                        <h6>{{ receipt.customerMobileNo }}</h6>
                                    </td>
                                </tr>
                                <tr
                                    v-if="receipt.customerAddress.length > 0 && defaultConfiguration.orderConfiguration.includeAddressInOrderReceipt == true">
                                    <td>
                                        <h6>Address:</h6>
                                    </td>
                                    <td>
                                        <h6>{{ receipt.customerAddress }}</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Order No</h6>
                                    </td>
                                    <td>
                                        <h4>{{ '#'+receipt.orderNo }}</h4>
                                    </td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            <h6>Items</h6>
                                        </td>
                                        <td>
                                            <h6>Service</h6>
                                        </td>
                                        <td>
                                            <h6>Qty.</h6>
                                        </td>
                                        <td>
                                            <h6>Pic.</h6>
                                        </td>
                                        <td>
                                            <h6>Amt.</h6>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody v-for="orderItems in receipt.orderItems" :key="orderItems">
                                    <tr>

                                        <td>
                                            <h6>{{ orderItems.itemName }}</h6>
                                        </td>
                                        <td>
                                            <h6>{{ orderItems.serviceName }}</h6>
                                        </td>
                                        <td>
                                            <h6>{{ orderItems.quantity }}</h6>
                                        </td>
                                        <td>
                                            <h6>{{ orderItems.pieceCount }}</h6>
                                        </td>
                                        <td>
                                            <h6>{{ orderItems.totalAmount }}</h6>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <h6 class="attributes"
                                                v-if="orderItems.garmentRemarks.length > 0 || orderItems.topUpServices.length > 0">
                                                <span v-if="orderItems.topUpServices.length > 0">-</span><span
                                                    class="text-nowrap"
                                                    v-for="topUpServices in orderItems.topUpServices"
                                                    :key="topUpServices">
                                                    {{ topUpServices.name + ', ' }}
                                                </span><br>
                                                <span v-if="orderItems.garmentRemarks.length > 0">-</span><span
                                                    class="text-nowrap"
                                                    v-for="garmentRemarks in orderItems.garmentRemarks"
                                                    :key="garmentRemarks">
                                                    {{ garmentRemarks.name + ', ' }}
                                                </span>
                                            </h6>

                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h6 v-if="orderItems.totalTupUpServiceAmount > 0">
                                                {{ orderItems.totalTupUpServiceAmount }}
                                            </h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5>Total Qty</h5>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h5>{{ receipt.totalItems }}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5>Dis. Amt</h5>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h5>{{ receipt.discountAmount }}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5>Gross Amt</h5>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h5>{{ receipt.totalGrossAmount }}</h5>
                                        </td>
                                    </tr>
                                    <tr v-if="defaultConfiguration.receiptConfiguration.printSubTaxDetails && receipt.totalTaxAmount>0">
                                        <td>
                                            <h6>{{ defaultConfiguration.subTaxName1 + ' (' + defaultConfiguration.subTaxRate1 + '%)  - ' + parseFloat((receipt.totalGrossAmount * defaultConfiguration.subTaxRate1) / 100).toFixed(2) }}
                                            </h6>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr v-if="defaultConfiguration.receiptConfiguration.printSubTaxDetails && receipt.totalTaxAmount>0">
                                        <td>
                                            <h6>{{ defaultConfiguration.subTaxName2 + ' (' + defaultConfiguration.subTaxRate2 + '%)  - ' + parseFloat((receipt.totalGrossAmount * defaultConfiguration.subTaxRate2) / 100).toFixed(2) }}
                                            </h6>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr v-if="defaultConfiguration.receiptConfiguration.printTaxAmountOnInvoice && receipt.totalTaxAmount>0">
                                        <td>
                                            <h5>{{ defaultConfiguration.taxName + ' (' + defaultConfiguration.taxRate + '%)'}}</h5>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h5>{{ receipt.totalTaxAmount }}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tr>
                                    <td>
                                        <h5>Net Amt</h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h5>{{ receipt.totalNetAmount }}</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h5>Paid Amt</h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h5>{{ receipt.totalPayedAmount }}</h5>
                                    </td>
                                </tr>

                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tr>
                                    <td>
                                        <h5>Due Amt</h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h5>{{ receipt.totalDueAmount }}</h5>
                                    </td>

                                </tr>
                                <tr
                                    v-if="receipt.previousDueAmount > 0 && defaultConfiguration.receiptConfiguration.printPreviousDueOnInvoice">
                                    <td>
                                        <h5>Previous Due Amt</h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h5>{{ receipt.previousDueAmount }}</h5>
                                    </td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row
                        v-if="receipt.previousDueAmount > 0 && defaultConfiguration.receiptConfiguration.printPreviousDueOnInvoice">
                        <b-col>
                            <table>
                                <tr>
                                    <td>
                                        <h5>Total Due Amt</h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <h5>{{ receipt.totalDueAmount + receipt.previousDueAmount }}</h5>
                                    </td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <hr>
                    <!-- <b-row>
                    <b-col>
                        <h6>Total Pics:- {{ receipt.totalPices }}</h6>
                    </b-col>
                </b-row> -->
                    <b-row>
                        <b-col>
                            <table>
                                <tr>
                                    <td>
                                        <h6>Total Pieces:- {{ receipt.totalPices }}</h6>
                                    </td>
                                </tr>
                                <tr v-if="receipt.urgent1">
                                    <td>
                                        <h6>Urgent 1:- Yes</h6>
                                    </td>
                                </tr>
                                <tr v-if="receipt.urgent2">
                                    <td>
                                        <h6>Urgent 2:- Yes</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Booked By :- {{ receipt.orderCreatedBy }}</h6>
                                    </td>
                                </tr>
                                <tr v-if="defaultConfiguration.receiptConfiguration.printOrderCriationTime">
                                    <td>
                                        <h6>Booking Date :- {{
                                            convertTimeStampToDateWithTimeLocal(receipt.orderCreateDate)
                                        }}</h6>
                                    </td>
                                </tr>
                                <tr v-if="defaultConfiguration.receiptConfiguration.printDueDate">
                                    <td>
                                        <h6>Ready On:-{{ convertTimeStampToDateWithTime(receipt.dueDate) }}</h6>
                                    </td>
                                </tr>
                            </table>
                            <!-- <h6>Total Pics:- {{ receipt.totalPices }}</h6>
                        <h6>Booked By :- {{ receipt.orderCreatedBy }}</h6>
                        <h6>Booking Date :- {{ convertTimeStampToDateWithTimeLocal(receipt.orderCreateDate) }}</h6>
                        <h6>Ready On:-{{ convertTimeStampToDateWithTime(receipt.dueDate) }}</h6> -->
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <h6>Thanks For Visit</h6>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <table>
                                <tr v-if="i == 1" v-for="termsAndCondition in termsAndCondition"
                                    :key="termsAndCondition">
                                    <td
                                        v-if="defaultConfiguration.receiptConfiguration.printTermConditionOnCustomerCopy">
                                        <h6>{{ '* '+termsAndCondition.termCondition }}</h6>
                                    </td>
                                </tr>
                                <tr v-if="i == 2" v-for="termsAndCondition in termsAndCondition"
                                    :key="termsAndCondition">
                                    <td v-if="defaultConfiguration.receiptConfiguration.printTermConditionOnStoreCopy">
                                        <h6>{{ '* '+termsAndCondition.termCondition }}</h6>
                                    </td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <b-row style="border: 1px dashed; width:58mm;"></b-row><br>
                </div>
            </div>
        </b-row>
    </b-card>
</template>
<script>
import {
    BCard, BRow, BCol, BImg, BButton
} from 'bootstrap-vue'
import moment from 'moment'
export default {
    components: {
        BCard, BRow, BCol, BImg, BButton
    },
    data() {
        return {
            storeName: '',
            storeAddress: '',
            storeContactNo: '',
            receiptDataList: [],
            termsAndCondition: [],
            defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration')),
            receiptCopy: [1]
        }
    },
    methods: {
        convertTimeStampToDateWithTimeLocal(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).local().format('DD-MM-YYYY hh:mm A');
            }
        },
        convertTimeStampToDateWithTime(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).format('DD-MM-YYYY hh:mm A');
            }
        },
        setOrderReceipt(receiptData, storeData, termsAndCondition) {
            this.storeName = storeData.storeName;
            this.storeAddress = storeData.address;
            this.storeContactNo = storeData.mobileNo;
            this.receiptDataList = []
            this.receiptDataList.push(receiptData);
            this.termsAndCondition = [];
            this.termsAndCondition = termsAndCondition;

            this.receiptCopy=[1];
            if (this.defaultConfiguration.receiptConfiguration.printStoreInvoiceCopy == true) {
                this.receiptCopy.push(2)
            }


        },

        printReceipt() {


            var divElement = document.querySelector(".receiptHeight");

            let elemHeight = divElement.offsetHeight;

            var divContents = document.getElementById("receipt").innerHTML;
            var printWindow = window.open('', '', '', '');
            printWindow.document.write('<html><head><title>' + this.storeName + '</title> <style> @page {size: 42mm ' + (elemHeight * 0.2645833333) / 3 + 'mm; margin: 0px;} table {width:58mm;} img{margin-top:-24px;} .storeName{margin-top:0px} .storeDetail{margin-top:-22px;} hr{margin-top:-24px; width:58mm; float: left;} h6{margin-top:-22px;} h5{margin-top:-25px;width:170px;} h4{margin-top:-28px} span{margin-top:-14px;} .attributes{margin-top:-31px} </style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(divContents);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
    }
}
</script>
<style>
table {
    width: 58mm;
}
</style>




