<template>
    <b-card title="Print QR Tags">
        <b-form>
            <b-row v-if="onlyForPrintTag">
                <b-col md="4">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" :options="storeNameOptions"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Order No">
                        <v-select :filterable="false" v-model="searchBoxForOrderNo" placeholder="Enter Order No"
                            :options="customersOrderNo" @search="onInputChangeOnInvoice" @input="onSelectedOrderNo">
                            <template #option="{ orderId, orderNo, customerName, customerId, customerMobileNo }">
                                <h4># {{ orderNo }}</h4>
                                <span>{{ customerName }} {{ '['+customerMobileNo + ']' }} </span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Garment Process Status">
                        <v-select placeholder="Select Garment Status" v-model="garmentProcessStatus"
                            :options="garmentProcessStatusOption"></v-select>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="From Date">
                        <flat-pickr v-model="fromDate" class="form-control" placeholder="To Date" />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="To Date">
                        <flat-pickr v-model="toDate" class="form-control" placeholder="To Date" />
                    </b-form-group>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" @click="getQRCodeDetails(0,false)" class="mt-2 mr-1">Filter</b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-col md="8">
                        <b-button variant="primary" v-b-toggle.sidebar-right-QrCode @click="previewQrTag">Preview
                            Tag</b-button>
                    </b-col>
                    <!-- <b-col md="2" class="text-center">
                        <b-button block variant="primary">Print</b-button>
                    </b-col> -->
                    <b-col md="4">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </b-col>
                </div>

                <!-- table -->
                <vue-good-table @on-selected-rows-change="selectionChanged" :line-numbers="true" :columns="columns"
                    :rows="rows" :rtl="direction" :search-options="{
                        enabled: true,
                        externalQuery: searchTerm
                    }" :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: false,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'customerName'" class="text-nowrap">
                            <span class="text-nowrap">{{ props.row.customerName }}</span><br>
                            <span class="text-nowrap font-size_table">{{ props.row.customerMobileNo }}</span><br>
                            <span class="text-nowrap font-size_table">{{ props.row.address }}</span>
                        </span>

                        <span v-else-if="props.column.field === 'itemName'" class="text-nowrap">
                            <span class="text-nowrap font-size_table">{{ props.row.itemName }}</span><br>
                            <span class="text-nowrap font-size_table">{{ props.row.garmentProcessStatus }}</span><br>
                            <span class="text-nowrap font-size_table">{{ 'Booking Date: '+convertTimeStampToDateWithTimeLocal(props.row.bookingDate) }}</span><br>
                            <span class="text-nowrap font-size_table">{{ 'Due Date: '+convertTimeStampToDateWithTime(props.row.dueDate) }}</span>
                        </span>

                        <span v-else-if="props.column.field === 'colors'">
                            <span v-for="colorsName in props.row.colors" :key="colorsName" class="text-nowrap">
                                {{ colorsName.name + ', ' }}
                            </span>
                        </span>

                        <span v-else-if="props.column.field === 'garmentRemarks'">
                            <span v-for="remarks in props.row.garmentRemarks" :key="remarks" class="text-nowrap">
                                {{ remarks.name + ', ' }}
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
            <b-sidebar v-if="selectedItems > 0" id="sidebar-right-QrCode" right shadow bg-variant="white" backdrop>
                <QRCodeTagGenerator ref="qrCodeTagGenerator"></QRCodeTagGenerator>
            </b-sidebar>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, VBToggle, BRow, BSidebar, BCol, BForm, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import api from '@/store/api'
import moment from 'moment'
import QRCodeTagGenerator from './QRCodeTagGenerator.vue'

export default {
    components: {
        VueGoodTable, BSidebar,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown, VBToggle,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, vSelect, flatPickr, QRCodeTagGenerator
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
            orderId: 0,
            garmentProcessStatusOption: [],
            garmentProcessStatus: '',
            fromDate: null,
            toDate: null,
            pageLength: 10,
            dir: false,
            columns: [
                // {
                //         label: 'Return Cause',
                //         field: 'returnCauses',
                //     },
                {
                    label: 'Customer',
                    field: 'customerName',
                },
                {
                    label: 'OrderNo',
                    field: 'orderNo'
                },
                {
                    label: 'Qr Code',
                    field: 'qrCode',
                },
                {
                    label: 'Garment Details',
                    field: 'itemName',
                },
                {
                    label: 'Service',
                    field: 'service.name',
                },
                {
                    label: 'Remark',
                    field: 'garmentRemarks',
                },
                {
                    label: 'Colors',
                    field: 'colors',
                },


            ],
            rows: [],
            searchTerm: '',
            customersOrderNo: [],
            searchBoxForOrderNo: '',
            defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration')),
            selectedItems: 0,
            orderItemList: [],
            onlyForPrintTag:true

        }
    },
    methods: {
        convertTimeStampToDateWithTimeLocal(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).local().format('DD-MM-YYYY hh:mm A');
            }
        },
        convertTimeStampToDateWithTime(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).format('DD-MM-YYYY hh:mm A');
            }
        },
        convertTimeStampToDate(value) {
            if (value == null) {
                return null;
            } else {
                return moment.utc(value).local(true).format('YYYY-MM-DD');
            }
        },
        getQRCodeDetails(orderDetailOrderId,onlyForPrintTag) {
            if(orderDetailOrderId>0){
                this.orderId=orderDetailOrderId;
                this.onlyForPrintTag=onlyForPrintTag;
            }
            let self = this;
            var axios = require('axios');
            var data = '';
            if (self.userId == undefined) {
                self.userId = 0;
            }
            let url = '';
            if (self.fromDate == null && self.toDate == null) {
                url = '/orders/getOrderItemPicesDetiledList?storeId=' + self.storeName.id + "&orderId=" + self.orderId + "&garmentProcessStatus=" + self.garmentProcessStatus;
            } else {
                url = '/orders/getOrderItemPicesDetiledList?storeId=' + self.storeName.id + "&orderId=" + self.orderId + "&garmentProcessStatus=" + self.garmentProcessStatus + "&fromDate=" + self.convertTimeStampToDate(self.fromDate) + "&toDate=" + self.convertTimeStampToDate(self.toDate);
            }
            axios(api.getApi('get', url, data))
                .then(function (response) {
                    if (response.data != null) {
                        self.rows = response.data;
                    } else {
                        self.rows = [];
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions.push({ 'id': 0, 'storeName': 'All' })
                    for (let index = 0; index < response.data.length; index++) {
                        self.storeNameOptions.push(response.data[index]);

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getGarmentProcessStatus() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getGarmentsProcessStatus', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.garmentProcessStatusOption = response.data;


                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onSelectedOrderNo(option) {
            // this.searchBoxForOrderNo = option.item;
            this.orderId = option.orderId;
            this.searchBoxForOrderNo = option.orderNo;
        },
        onInputChangeOnInvoice(orderNo) {
            if (orderNo === '' || orderNo === undefined) {
                return
            }
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersFilteredListByOrderNoContaining/' + orderNo, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersOrderNo = response.data;
                    } else {
                        self.customersOrderNo = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        previewQrTag() {
            const orderItemsPieces = [];

            for (let index = 0; index < this.orderItemList.length; index++) {
                orderItemsPieces.push(this.orderItemList[index]);
            }
            if (orderItemsPieces.length > 0) {
                this.$refs.qrCodeTagGenerator.setQrCodeTagDesign(
                    this.defaultConfiguration.barcodeConfiguration.barCodeWidth,
                    this.defaultConfiguration.barcodeConfiguration.barCOdeHeight,
                    this.defaultConfiguration.barcodeConfiguration.barCodeAlign,
                    this.defaultConfiguration.barcodeConfiguration.printLogo,
                    this.defaultConfiguration.barcodeConfiguration.printBookingNo,
                    this.defaultConfiguration.barcodeConfiguration.qrcode,
                    this.defaultConfiguration.barcodeConfiguration.printCustomerName,
                    this.defaultConfiguration.barcodeConfiguration.printAddress,
                    this.defaultConfiguration.barcodeConfiguration.printProcess,
                    this.defaultConfiguration.barcodeConfiguration.printItemTotal,
                    this.defaultConfiguration.barcodeConfiguration.printRemark,
                    this.defaultConfiguration.barcodeConfiguration.printColour,
                    this.defaultConfiguration.barcodeConfiguration.printBookingDate,
                    this.defaultConfiguration.barcodeConfiguration.printOrderTime,
                    this.defaultConfiguration.barcodeConfiguration.printDueDate,
                    this.defaultConfiguration.barcodeConfiguration.printItemName,
                    this.defaultConfiguration.barcodeConfiguration.printStoreName,
                    orderItemsPieces, this.storeName.storeName);
            } else {
                this.$swal({
                    title: '',
                    text: "Select Order Item Pieces.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },
        selectionChanged(params) {
            if (params.selectedRows.length > 0) {
                this.selectedItems = 1;
            } else {
                this.selectedItems = 0;
            }
            this.orderItemList = params.selectedRows;
        },
    },
    computed: {

    },
    created() {
        this.getStoreName();
        this.getGarmentProcessStatus();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.font-size_table {
    font-size: 12px;
}
</style>
